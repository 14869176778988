import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  getLeaderBoardAction,
  getLeaderBoardForDownloadAction,
  getLeaderBoardFiltersAction,
  getQuizzesAction,
  getQuizFiltersAction,
  getQuizDetailsAction,
  gradeUserAnswerAction,
  getQuizAllAnswersAction,
  getQuizSlidesInfoAction,
  getQuizAnswersForDownloadAction,
  getLessonsAction,
  getLessonFiltersAction,
  getLessonUsersAction,
  notifyLessonUsersAction,
  notifyQuizUsersAction,
  getQuizUsersAction,
} from '../../actions/analytics.actions';
import {
  getLeaderBoardApi,
  getLeaderBoardFiltersApi,
  getQuizzesApi,
  getQuizFiltersApi,
  getQuizDetailsApi,
  gradeUserAnswerApi,
  getQuizAllAnswersApi,
  getQuizSlidesInfoApi,
  getLessonsApi,
  getLessonFiltersApi,
  getLessonUsersApi,
  notifyLessonUsersApi,
  notifyQuizUsersApi,
  getQuizUsersApi,
} from '../../services/analytics.service';
import { gradeUserAnswerError } from '../../selectors/analytics.selector';

export const watchGetLeaderBoard = {
  ...getLeaderBoardAction,
  api: getLeaderBoardApi,
};

export const watchGetLeaderBoardForDownload = {
  ...getLeaderBoardForDownloadAction,
  api: getLeaderBoardApi,
  take: takeEvery,
};

export const watchGetLeaderBoardFilters = {
  ...getLeaderBoardFiltersAction,
  api: getLeaderBoardFiltersApi,
};

export const watchGetQuizzes = {
  ...getQuizzesAction,
  api: getQuizzesApi,
};

export const watchGetQuizFilters = {
  ...getQuizFiltersAction,
  api: getQuizFiltersApi,
};

export const watchGetQuizDetails = {
  ...getQuizDetailsAction,
  api: getQuizDetailsApi,
};

export const watchGradeUserAnswer = {
  ...gradeUserAnswerAction,
  api: gradeUserAnswerApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(gradeUserAnswerError);
    if (!err) {
      yield put(
        getQuizAllAnswersAction.request({
          page: action.params.page,
          id: action.params.quizId,
          attempt: action.params.attempt,
          groupIds: action.params.userGroupIds,
        }),
      );
      yield put(
        getQuizSlidesInfoAction.request({
          id: action.params.quizId,
          attempt: action.params.attempt,
          groupIds: action.params.userGroupIds,
        }),
      );
    }
  },
};

export const watchGetQuizAllAnswers = {
  ...getQuizAllAnswersAction,
  api: getQuizAllAnswersApi,
};

export const watchGetQuizSlidesInfo = {
  ...getQuizSlidesInfoAction,
  api: getQuizSlidesInfoApi,
};

export const watchGetQuizAnswersForDownload = {
  ...getQuizAnswersForDownloadAction,
  api: getQuizAllAnswersApi,
  take: takeEvery,
};

export const watchGetLessons = {
  ...getLessonsAction,
  api: getLessonsApi,
};

export const watchGetLessonFilters = {
  ...getLessonFiltersAction,
  api: getLessonFiltersApi,
};

export const watchGetLessonUsers = {
  ...getLessonUsersAction,
  api: getLessonUsersApi,
};

export const watchNotifyLessonUsers = {
  ...notifyLessonUsersAction,
  api: notifyLessonUsersApi,
  toast: {
    success: 'Reminder is sent successfully.',
  },
};

export const watchNotifyQuizUsers = {
  ...notifyQuizUsersAction,
  api: notifyQuizUsersApi,
  toast: {
    success: 'Reminder is sent successfully.',
  },
};

export const watchGetQuizUsers = {
  ...getQuizUsersAction,
  api: getQuizUsersApi,
};
